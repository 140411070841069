import { memo, useCallback, useRef } from "react";
import { SectionTitle } from "../SectionTitle";
import { LeftArrow } from "Components/SwiperButtons/LeftArrow";
import { RightArrow } from "Components/SwiperButtons/RightArrow";
import { Swiper, SwiperSlide } from "swiper/react";

let parnterslist = [
  {
    img: "/images/partners/leader.svg",
  },
  {
    img: "/images/partners/visol.webp",
  },
  {
    img: "/images/partners/betlivepartnerlogo.svg",
  },
  {
    img: "/images/partners/europeanSchool.png",
  },
  {
    img: "/images/partners/socarLogo.png",
  },
  {
    img: "/images/partners/sevsamora.png",
  },
  {
    img: "/images/partners/pirveli.png",
  },
  {
    img: "/images/partners/optimoLogo.png",
  },
];

export const Partners = memo(function ParnersComponent() {
  const sliderRef = useRef<any>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div className="relative md:mb-[60px] md:after:w-0 md:before:w-0 after:content-[''] after:absolute after:bg-partline1 after:w-[50%] after:top-[50px] after:left-0 after:h-[332px] before:content-[''] before:bg-partline2 before:absolute before:w-[50%] before:top-[50px] before:right-0 before:h-[332px]">
      <div className="container relative z-10">
        <div className="flex justify-between items-center">
          <SectionTitle title="პარტნიორი ორგანიზაციები" />
          <div className="flex items-center gap-5 md:hidden">
            <LeftArrow handlePrev={handlePrev} />
            <RightArrow handleNext={handleNext} />
          </div>
        </div>
        <div className="h-[280px] md:h-auto md:mt-[0] mt-[80px]">
          <Swiper
            ref={sliderRef}
            spaceBetween={30}
            breakpoints={{
              640: {
                slidesPerView: 4,
              },
              832: {
                slidesPerView: 5,
              },
              1080: {
                slidesPerView: 6,
              },
            }}
            className="sm:hidden"
          >
            {parnterslist.map((_, i) => (
              <SwiperSlide key={i} className="flex flex-col gap-1">
                <img
                  className="h-[83px] object-contain selector"
                  src={_.img}
                  alt={`${i}-vector`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="hidden sm:grid sm:grid-cols-2 sm:gap-6">
            {parnterslist.map((_, i) => (
              <div key={i} className="flex flex-col gap-1">
                <img
                  className="h-[61px] object-contain"
                  src={_.img}
                  alt={`${i}-children-vector`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
