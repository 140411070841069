import { useCallback, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { VitaPercent } from "Components/Buttons/VitaPercent";
import { CustomMedicaments } from "Components/Modals/Pharmacy/CustomMedicaments";
import { MedicamentsById } from "Components/Modals/Pharmacy/MedicamentsById";
import { MedicamentsGroup } from "Components/Modals/Pharmacy/MedicamentsGroup";
import { VitaminGroups } from "Components/Modals/Pharmacy/VitaminGroups";
import { OffersBlock } from "Components/Pharmacy/OffersBlock";
import useWindowSize from "Hooks/useWindowSize";
import {
  categories,
  cats,
  daysList,
  medicaments,
  medicamentsWithCat,
  proteinsCardsData,
  Seasonalmedications,
  weekendCardsModalData,
  weekendDiscountCardsData,
} from "JSON/PSP";
import { PharmacyContact } from "Components/Modals/Pharmacy/PharmacyContact";
import { Link } from "react-router-dom";
import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { useGetUserQuery } from "Reducers/ApiSlice";
import MedicalCard from "Components/Cards/MedicalCard";
import classNames from "classnames";
import { Menu } from "Components/PageSections/Clinics/Menu/Menu";

export type Medication = {
  medicine: string;
  price: number;
  sale: number;
  salePrice: number;
  img?: string;
};

export type SeasonalmedicationsType = {
  "გაციების ჩაი": Medication[];
  "სპრეი და ჩასაწვეთებელი": Medication[];
  "ტაბლეტები და კაფსულები": Medication[];
  "სანთლები და პლასტირები": Medication[];
  სიროფი: Medication[];
};

export const Psp = () => {
  const { width } = useWindowSize();
  const [weekPagination, setWeekPagination] = useState(false);
  const [isOpenMedicaments, setIsOpenMedicaments] = useState<boolean>(false);
  const [medicamentsId, setMedicamentsId] = useState<null | number>(null);
  const [medicPagination, setMedicPagination] = useState(false);
  const [category, setCategory] = useState<any>(categories[0].slug);
  // mobile modals
  const [isOpenMedicamentsGroup, setIsOpenMedicamentsGroup] = useState(false);
  const [isOpenVitaminModal, setIsOpenVitaminModal] = useState(false);
  const [isOpenMedicamentsById, setIsOpenMedicamentsById] = useState(null);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const { error } = useGetUserQuery();
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  const swiperRef = useRef<any>(null);
  const [selectedMedicaments, setSelectedMedicaments] = useState<Medication[]>(
    Seasonalmedications["გაციების ჩაი"] || []
  );
  const [clickedItem, setClickedItem] = useState<number | null>(0);
  const [showMore, setShowMore] = useState(true); // State to track if "more" items are shown
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const checkScrollPosition = () => {
    if (menuRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = menuRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scroll = (scrollOffset: number) => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: scrollOffset, behavior: "smooth" });
    }
  };

  const scrollTo = useCallback(
    (index: number) => {
      if (menuRef.current && buttonRefs.current[index]) {
        const container = menuRef.current;
        const tabElement = buttonRefs.current[index];

        // Ensure tabElement is not null
        if (tabElement) {
          const tabLeft = tabElement.offsetLeft;
          const tabWidth = tabElement.offsetWidth;
          const containerWidth = container.offsetWidth;

          // Calculate the scroll position to center the clicked item
          const scrollPosition = tabLeft - containerWidth / 2 + tabWidth / 3;

          // Scroll to the calculated position
          container.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
        }
      }
    },
    [clickedItem]
  );

  const handleMenuClick = (
    menuItem: keyof typeof Seasonalmedications,
    index: number
  ) => {
    const children = Seasonalmedications[menuItem];
    setClickedItem(index);
    setSelectedMedicaments(children); // Update the children based on the clicked item

    // Scroll clicked item into view
    scrollTo(index); // Call the scrollTo function with the clicked index
  };
  useEffect(() => {
    checkScrollPosition();
    if (menuRef.current) {
      menuRef.current.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      if (menuRef.current) {
        menuRef.current.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [Seasonalmedications]);

  const handleShowMoreToggle = () => {
    setShowMore(!showMore); // Toggle between showing more or less
  };
  return (
    <div className="container mt-3 sm:mt-2 md:mt-5 sm:px-[0] sm:w-full sm:max-w-full overflow-hidden">
      <CustomMedicaments
        open={isOpenMedicaments}
        close={() => setIsOpenMedicaments(false)}
        data={weekendCardsModalData?.filter((e) => e.id == medicamentsId)[0]}
      />

      {width <= 640 && (
        <>
          <MedicamentsGroup
            open={isOpenMedicamentsGroup}
            close={() => setIsOpenMedicamentsGroup(false)}
          />
          <VitaminGroups
            open={isOpenVitaminModal}
            close={() => setIsOpenVitaminModal(false)}
            openList={(id: any) => setIsOpenMedicamentsById(id)}
          />
          <MedicamentsById
            open={isOpenMedicamentsById !== null}
            close={() => setIsOpenMedicamentsById(null)}
            closeEveryModal={() => {
              setIsOpenMedicamentsById(null);
              setIsOpenVitaminModal(false);
            }}
            list={
              weekendCardsModalData?.filter(
                (e) => e.id == isOpenMedicamentsById
              )[0]
            }
          />

          <PharmacyContact
            open={isContactOpen}
            close={() => setIsContactOpen(false)}
            data={["+ 995 568 820 424"]}
            img={"/images/pharmacy/pspv2.svg"}
          />
        </>
      )}
      <div className="w-full sm:px-4">
        <BasicBreadcrumbs
          breadcrumbs={[
            {
              title: "აფთიაქები",
              link: "/clinics/aptiakebi",
            },
            {
              title: "PSP - აფთიაქი",
              link: "/",
            },
          ]}
        />
      </div>

      <div className="flex sm:px-[16px] mt-6 xl:flex-col gap-4">
        <div className="h-[255px] sm:flex-col sm:items-center xl:h-auto bg-blue-20 rounded-[20px] w-full p-[49px] sm:p-[32px] flex items-start gap-9">
          <div className="w-[77px] h-[77px] min-w-[77px] min-h-[77px] rounded-[50%] overflow-hidden flex items-center justify-center">
            <img
              width={77}
              height={77}
              className="object-contain min-w-[77px] min-h-[77px]"
              src="/images/pharmacy/psp.svg"
              alt="psp"
            />
          </div>
          <div className="flex flex-col gap-[20px]">
            <p className="text-blackPrimary-100 font-medium text-md sm:text-center">
              PSP პირველი ქართული კომპანიაა, რომელიც 1994 წლიდან გვთავაზობს
              ევროპული, გარანტირებული ხარისხის მედიკამენტებს. PSP 300-ზე მეტი
              აფთიაქით არის წარმოდგენილი და მოიცავს საქართველოს თითქმის ყველა
              კუთხესა და თბილისის უბანს. ყოველთვიურად 2 მილიონი მომხმარებელი
              ირჩევს PSP-ს თავის “ოჯახის აფთიაქად”.
            </p>
            <div className="sm:hidden flex items-center gap-3 font-regular text-blackPrimary-100 text-rg">
              <ReactSVG src="/images/icons/phoneicon.svg" />+ 995 568 820 424
            </div>
          </div>
        </div>
        <div className="relative xl:h-[200px] sm:h-[178px] bg-blue-100 min-w-[490px] 2xl:min-w-[400px] sm:min-w-full xl:w-full h-[255px] overflow-hidden rounded-[20px]">
          <div className="relative p-[30px] z-10">
            <h2 className="text-[#fff] font-demi text-[28px] sm:text-rg mb-[10px]">
              PSP-ში ფასდაკლების მისაღებად, აუცილებელია VITA ბარათის გარდა PSP-ს ოჯახის ბარათის ქონაც
            </h2>
            {/* <span className="text-[#fff] sm:text-sm font-medium text-md">
              პროდუქტების ფასდაკლებით მისაღებად საჭიროა იქონიოთ PSP-ს ბარათი
            </span> */}
          </div>
          <img
            className="object-contain sm:w-[180px] absolute bottom-0 right-0"
            src="/images/pharmacy/handbanner.png"
          />
        </div>
      </div>
      <div className="mt-[48px]">
        <h2 className="text-[24px] sm:text-[14px] px-[16px] text-blackPrimary-100 font-bold mb-[18px]">
          ფასდაკლება მედიკამენტებზე
        </h2>
        <div className="bg-gray-30 p-[24px] sm:px-0 sm:rounded-[0px] rounded-[20px]">
          <div>
            <div className="flex sm:px-[16px] mb-[14px] items-center gap-1">
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 36px; height: 36px;");
                }}
                className="sm:hidden"
                src="/images/icons/offersicon.svg"
              />
              <h2 className="text-[24px] sm:text-rg font-demi text-blackPrimary-100">
                ფასდაკლების დღეები ვიტა ბარათის მფლობელებს
              </h2>
              <ReactSVG
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "width: 36px; height: 36px;");
                }}
                className="sm:hidden"
                src="/images/icons/offersicon.svg"
              />
            </div>{" "}
            <div className="flex items-center mb-[30px] gap-4">
              <img
                src="/images/icons/vitaicon.svg"
                alt="icon"
                width={24}
                height={24}
                className="mt-1 object-cover md:hidden"
              />
              <span className="sm:px-[16px] text-md sm:text-rg font-demi text-blackPrimary-60 block">
                VITA ბარათით კვირის ნებისმიერ დღეს ფასდაკლებით შეიძენთ ყველა იმ
                მედიკამენტს, რომელზეც PSP-ში ფასდაკლება ვრცელდება მხოლოდ სააქციო
                დღეებში
              </span>
            </div>
            <div
              className={`${
                width > 1527 ? "px-5" : "pl-0"
              } relative max-h-[94px] sm:rounded-[0] overflow-hidden bg-blue-30 rounded-[12px] py-[20px] cursor-pointer`}
            >
              <img
                className="absolute left-0 top-[50%] translate-y-[-50%]"
                src="/images/pharmacy/flowerleft.svg"
                alt=""
              />
              <img
                className="absolute right-0 top-0"
                src="/images/pharmacy/flowerright.svg"
                alt=""
              />
              <div className="relative hidescroll overflow-x-auto flex gap-[12px] z-10 w-full">
                <Swiper
                  spaceBetween={12}
                  breakpoints={{
                    200: {
                      slidesPerView: 1.2,
                    },
                    600: {
                      slidesPerView: 2.2,
                    },
                    832: {
                      slidesPerView: 3.2,
                    },
                    1080: {
                      slidesPerView: 5.1,
                    },
                    1280: {
                      slidesPerView: 6.2,
                    },
                    1527: {
                      slidesPerView: 7,
                    },
                  }}
                  className={`${
                    width > 1527 ? "" : "pr-5 pl-5 sm:pr-4 sm:pl-4"
                  } md:px-[32px] w-full`}
                >
                  {daysList?.map((item, i) => {
                    return (
                      <SwiperSlide
                        key={i}
                        className="w-full flex justify-between bg-[#fff] rounded-[12px] p-[12px]"
                      >
                        <div className="flex flex-col gap-0">
                          <h2
                            className={`${
                              item.isVita
                                ? "text-raspberry-100"
                                : "text-blackPrimary-60"
                            } text-rg mb-0 font-bold`}
                          >
                            {item.day}
                          </h2>
                          <span
                            className={`${
                              item.isVita
                                ? "text-raspberry-100"
                                : "text-blackPrimary-60"
                            } text-sm font-regular`}
                          >
                            ფასდაკლების დღე
                          </span>
                        </div>
                        <img
                          className="min-w-[30px] min-h-[30px]"
                          width={30}
                          height={30}
                          src={item.img}
                          alt="vita"
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>

          {width > 640 && (
            <div className="grid grid-cols-3 customxlsize:grid-cols-2 md:grid-cols-1 md:gap-4 mt-[30px] gap-8">
              {cats.map((item, index) => (
                <div
                  className="bg-[#fff] gap-3 flex items-center justify-between p-4 rounded-[12px]"
                  key={index}
                >
                  <img
                    width={44}
                    height={44}
                    className="min-w-[44px] min-h-[44px]"
                    src={item.img}
                    alt={item.title}
                  />
                  <h2 className="font-demi mr-auto text-rg text-blackPrimary-100">
                    {item.title}
                  </h2>
                  <button className="bg-raspberry-100 text-rg p-[8px] rounded-[8px] text-[#fff] font-bold whitespace-nowrap">
                    {item.discount}
                  </button>
                </div>
              ))}
            </div>
          )}
          {width <= 640 && (
            <div className="px-[16px] my-[20px]">
              <button
                onClick={() => setIsOpenMedicamentsGroup(true)}
                className="w-full rounded-[12px] flex items-center justify-between gap-3 py-[20px] px-[17px] bg-raspberry-10"
              >
                <ReactSVG src="/images/icons/medicon.svg" />
                <span className="flex flex-col items-start mr-auto">
                  <span className="text-blackPrimary-100 font-demi text-rg">
                    მედიკამენტების ჯგუფები
                  </span>
                  <span className="text-raspberry-100 font-demi text-sm">
                    იხილე სრული ჩამონათვალი
                  </span>
                </span>
                <ReactSVG src="/images/icons/arrowright.svg" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-[48px]">
        <h2 className="text-[24px] sm:text-rg sm:px-[16px] text-blackPrimary-100 font-bold mb-[18px]">
          გაციებისა და ხველების სეზონური მკურნალობა
        </h2>
        <div className="w-full bg-[#E8ECF44D] p-[24px] md:px-4 sm:rounded-[0px] rounded-[20px]">
          <div className="flex">
            <div className="flex items-start gap-4">
              <img
                src="/images/icons/vitaicon.svg"
                alt="icon"
                width={24}
                height={24}
                className="mt-1 md:hidden"
              />
              <p className="text-[16px] font-medium leading-6 text-[#070A14]">
                უმკურნალეთ გაციებას, გრიპსა და ხველას 50%-მდე ფასდაკლებით, 1
                ნოემბრიდან 31 იანვრის ჩათვლით . ფასდაკლება ვრცელდება მოცემულ,
                კონკრეტულ მედიკამენტებზე როგორც კოლოფით, ასევე საცალოდ
                შეძენისას.
              </p>
            </div>

            <div className="flex gap-4 intems-center md:hidden">
              <button
                className="w-10 h-10 flex justify-center items-center rounded-full bg-[#3A6CE833] shadow-[2px_2px_10px_#3838381A]"
                ref={setPrevEl} // Assign ref for the previous button
                onClick={() => swiperRef.current?.slidePrev()} // Correct the onClick handler
              >
                <img
                  src="/images/icons/Arrow-slide-Left.png"
                  alt="Previous"
                  width={24}
                  height={24}
                />
              </button>

              <button
                className="w-10 h-10 flex justify-center items-center rounded-full bg-[#3A6CE833] shadow-[2px_2px_10px_#3838381A]"
                ref={setNextEl} // Assign ref for the next button
                onClick={() => swiperRef.current?.slideNext()} // Correct the onClick handler
              >
                <img
                  src="/images/icons/Arrow-slide-right.png"
                  alt="Next"
                  width={24}
                  height={24}
                />
              </button>
            </div>
          </div>
          <div className="flex md:flex-col gap-8 mt-8">
            <div className="flex bg-[#3A6CE81A] rounded-xl md:px-4 relative overflow-hidden">
              {/* Left Arrow Button */}
              <button
                className={`hidden md:flex items-center ${
                  !canScrollLeft ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => canScrollLeft && scroll(-150)}
                disabled={!canScrollLeft}
              >
                <img src="/images/icons/arrowleft.svg" alt="arrow" width={24} />
              </button>

              {/* Left Sidebar (Menu) */}

              {/* Menu Items */}
              <div
                ref={menuRef}
                className="flex flex-col md:flex-row gap-[25px] md:gap-4 w-[328px] h-[383px] md:h-[58px] md:w-full md:px-6 pr-6 py-8 md:py-0 overflow-hidden md:overflow-x-scroll hide-scrollbar "
              >
                {Object.keys(Seasonalmedications).map((item: any, index) => {
                  const isActive = clickedItem === index;

                  return (
                    <div
                      key={item}
                      ref={(el) => (buttonRefs.current[index] = el)}
                      onClick={() => handleMenuClick(item, index)}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                      className="flex justify-between items-center md:flex-none z-10"
                    >
                      <div className="flex items-center">
                        {/* Underline Indicator for Active Item */}
                        <span
                          className={classNames(
                            "h-6 w-1 rounded-tr-[4px] rounded-br-[4px] md:hidden",
                            { "bg-[#3A6CE8]": isActive }
                          )}
                        />
                        <div className="flex h-full flex-col justify-between md:mt-4 ">
                          <h3
                            className={classNames(
                              "ml-6 md:mr-6 md:ml-0 text-[#070A14] font-semibold text-base",
                              { "text-[#3A6CE8]": isActive }
                            )}
                          >
                            {item}
                          </h3>
                          {/* Underline for Active Item on Medium Screens */}
                          <span
                            className={classNames(
                              "h-[2px] mt-4 w-[80%] transition-all duration-500 ease-in-out rounded-full hidden md:flex", // Default state with width of 0
                              { " bg-[#3A6CE8] z-4": isActive } // Animated state when active
                            )}
                          />
                        </div>
                      </div>
                      {/* Arrow Indicator for Mobile */}
                      <img
                        src={
                          isActive
                            ? "/images/icons/blueArrowright.svg"
                            : "/images/icons/Arrowright.svg"
                        }
                        alt=""
                        className="md:hidden"
                      />
                    </div>
                  );
                })}

                {/* Image positioned centrally for medium screens */}
                <div className="flex justify-center items-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:overflow-hidden ml-6 mt-[2px]">
                  <img
                    src="/images/tree/menu-tree.png"
                    alt="decorative tree"
                    width={167}
                    className="object-contain"
                  />
                </div>
              </div>

              {/* Right Arrow Button */}
              <button
                className={`hidden md:flex items-center ${
                  !canScrollRight ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => canScrollRight && scroll(150)}
                disabled={!canScrollRight}
              >
                <img
                  src="/images/icons/arrowright.svg"
                  alt="arrow"
                  width={24}
                />
              </button>
            </div>
            {/* Right Content (Medical Cards) */}
            <div className="w-[calc(100%-355px)] md:w-full flex">
              {!!selectedMedicaments && (
                <MedicalCard
                  medicinedata={selectedMedicaments}
                  prevEl={prevEl}
                  nextEl={nextEl}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[48px]">
        <div>
          <h2 className="text-[24px] sm:text-rg sm:px-[16px] text-blackPrimary-100 font-bold mb-[18px]">
            ფასდაკლება ვიტამინებსა და საკვებ დანამატებზე
          </h2>
          <div className="bg-blue-10 sm:rounded-[0] p-[24px] rounded-[20px]">
            <div className="mb-[44px]">
              <div className="flex mb-[14px] sm:mb-[6px] items-center gap-4">
                <img
                  src="/images/icons/vitaicon.svg"
                  alt="icon"
                  width={24}
                  height={24}
                  className="mt-1 md:hidden"
                />
                <p className="text-base font-medium leading-6 text-[#070A1499] text-[16px]">
                  მუდმივმოქმედი, 35% ფასდაკლება მოცემული ოთხი ბრენდის სრულ
                  ასორტიმენტზე. ფასდაკლებით სარგებლობა შესაძლებელია ყოველდღე და
                  ულიმიტოდ.
                </p>
              </div>
              <div className="grid grid-cols-4 customxlsize:grid-cols-2 md:grid-cols-1 mt-[30px] sm:gap-4 gap-8">
                {medicaments.map((item, index) => (
                  <div
                    className="bg-[#fff] gap-3 flex items-center justify-between p-[10px] px-[12px] sm:py-[16px] sm:px-[24px] rounded-[12px]"
                    key={index}
                  >
                    <img
                      width={100}
                      height={100}
                      className="min-w-[100px] min-h-[100px] sm:max-w-[60px] sm:min-w-[60px] sm:min-h-[60px] sm:max-h-[60px]"
                      src={item.img}
                      alt={item.title}
                    />
                    <div className="mr-auto">
                      <h2 className="font-bold text-[14px] text-[#070A14]  sm:text-[#383838]">
                        {item.title}
                      </h2>
                      <h3 className="font-demi text-[12px]text-[#070A14]  sm:text-[#383838]">
                        {item.title2}
                      </h3>
                    </div>
                    <button className="bg-raspberry-100 text-rg p-[8px] rounded-[8px] text-[#fff] font-bold whitespace-nowrap">
                      {item.discount}
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* <div>
              <div className="flex mb-[14px] items-center gap-1">
                <ReactSVG
                  beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 36px; height: 36px;");
                  }}
                  src="/images/pharmacy/star.svg"
                />
                <h2 className="text-[24px] sm:text-rg font-demi text-blackPrimary-100">
                  შაბათ-კვირის ფასდაკლება - 40%
                </h2>
                <ReactSVG
                  beforeInjection={(svg) => {
                    svg.setAttribute("style", "width: 36px; height: 36px;");
                  }}
                  src="/images/pharmacy/star.svg"
                />
              </div>
              <span className="text-md sm:text-rg font-demi text-blackPrimary-60 block mb-[30px]">
                VITA ბარათით მთელი წლის განმავლობაში ყოველ შაბათ-კვირას 40%
                ფასდაკლებით შეიძენთ ვიტამინებისა და საკვები დანამატების შერჩეულ
                მედიკამენტებს
              </span>
              {width > 640 && (
                <>
                  <div className="grid gap-5 xl:grid-cols-4 md:grid-cols-3 grid-cols-5">
                    {weekendDiscountCardsData.map(
                      (item, index) =>
                        (weekPagination || index < 5) && (
                          <div
                            key={index}
                            onClick={() => {
                              setMedicamentsId(item.cardId);
                              setIsOpenMedicaments(true);
                            }}
                            className="group transition-all w-full h-[265px] bg-[#fff] overflow-hidden rounded-[16px]"
                          >
                            <VitaPercent
                              className="bg-raspberry-100 text-[#fff!important] max-w-[107px] m-5 absolute z-10"
                              percent={item?.discountNumber}
                            />
                            <div className="w-full p-2 flex h-[calc(100%-20px)] transition-all group-hover:h-[calc(100%-50px)] items-center justify-center">
                              <img
                                className="object-contain group-hover:scale-75 transition-all"
                                src={item?.imageUrl}
                                alt={item?.altText}
                              />
                            </div>
                            <div className="group-hover:bottom-0 transition-all cursor-pointer relative h-[50px] bg-blue-30 flex items-center justify-center">
                              <span className="flex gap-1 items-center opacity-0 transition-all group-hover:opacity-100 font-demi text-md">
                                იხილეთ მეტი
                                <ReactSVG
                                  className="mt-1"
                                  src="/images/icons/arrowright.svg"
                                  wrapper="span"
                                />
                              </span>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                  <div className="flex justify-end my-[20px]">
                    <button
                      onClick={() => setWeekPagination(!weekPagination)}
                      className="outline-none border-none flex items-center gap-2 text-blue-100 underline font-demi text-[20px]"
                    >
                      <ReactSVG src="/images/icons/eye.svg" wrapper="span" />
                      {weekPagination ? "იხილე ნაკლები" : "იხილე მეტი"}
                    </button>
                  </div>
                </>
              )}
              {width <= 640 && (
                <button
                  onClick={() => setIsOpenVitaminModal(true)}
                  className="w-full rounded-[12px] flex items-center justify-between gap-3 py-[20px] px-[17px] bg-[#fff]"
                >
                  <ReactSVG src="/images/icons/medicon.svg" />
                  <span className="flex flex-col items-start mr-auto">
                    <span className="text-blackPrimary-100 font-demi text-rg">
                      ვიტამინების ჯგუფები
                    </span>
                    <span className="text-blue-100 font-demi text-sm">
                      იხილე სრული ჩამონათვალი
                    </span>
                  </span>
                  <ReactSVG src="/images/icons/arrowright.svg" />
                </button>
              )}
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="mt-[48px]"> */}
      {/* <div className="bg-gray-30 p-[24px] rounded-[20px]"> */}
      {/* <div className="sm:flex items-center justify-between">
            <h2 className="hidden sm:text-blackPrimary-100 sm:font-bold sm:text-rg sm:block">
              {categories?.filter((e) => e.slug == category)[0].title}
            </h2>
            <div className="flex sm:gap-1 items-center">
              {categories?.map((item, index) => (
                <div key={index} className="flex items-center">
                  <div
                    onClick={() => setCategory(item.slug)}
                    className={`${
                      width <= 640
                        ? item.slug == category
                          ? "bg-blue-100"
                          : "bg-blackPrimary-10"
                        : "bg-blue-100"
                    } cursor-pointer w-[48px] h-[48px] rounded-[50%]  flex items-center justify-center`}
                  >
                    <ReactSVG src={item.img} />
                  </div>
                  <span
                    className={`${
                      width <= 640 && "hidden"
                    } overflow-hidden flex items-center transition-all rounded-[100px] font-bold text-[#fff] ${
                      item.slug == category
                        ? "bg-blue-100 px-[16px] w-auto text-[14px] h-[48px]"
                        : "w-0 text-[0px] h-0"
                    }`}
                  >
                    {item.title}
                  </span>
                </div>
              ))}
            </div>
          </div> */}
      {/* <div className="grid grid-cols-4 menuSize:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 mt-[30px] gap-[24px]">
            {medicamentsWithCat[category].map(
              (item: any, index: number) =>
                (medicPagination || index < (width > 640 ? 8 : 4)) && (
                  <div
                    className="bg-[#fff] gap-3 flex items-center justify-between p-4 sm:py-[12px] rounded-[12px]"
                    key={index}
                  >
                    <img
                      width={100}
                      height={100}
                      className="min-w-[100px] sm:min-w-[60px] sm:w-[60px] sm:min-h-[60px] sm:h-[60px] min-h-[100px]"
                      src={item.image}
                      alt={item.title}
                    />
                    <div className="mr-auto">
                      <h2 className="font-demi mr-auto text-rg text-blackPrimary-100">
                        {item.headerText}
                      </h2>
                      <h2 className="font-demi mr-auto text-sm text-blackPrimary-100">
                        {item.title}
                      </h2>
                    </div>
                    <button className="bg-raspberry-100 text-rg p-[8px] sm:px-[12px] rounded-[8px] text-[#fff] font-bold whitespace-nowrap">
                      -{item.discount}%
                    </button>
                  </div>
                )
            )}
          </div> */}
      {/* {medicamentsWithCat[category]?.length > (width > 640 ? 8 : 4) && (
            <div className="flex sm:justify-center justify-end my-[20px]">
              <button
                onClick={() => setMedicPagination(!medicPagination)}
                className="outline-none border-none flex items-center gap-2 text-blackPrimary-60 underline font-demi text-[20px] sm:text-md sm:text-raspberry-100"
              >
                {width < 640 ? (
                  <>{medicPagination ? "ნაკლები" : "ყველა"}</>
                ) : (
                  <>
                    <ReactSVG src="/images/icons/eyedark.svg" wrapper="span" />
                    {medicPagination ? "იხილე ნაკლები" : "იხილე მეტი"}
                  </>
                )}
              </button>
            </div>
          )} */}
      {/* </div> */}
      {/* </div> */}

      {/* <div className="mt-[48px] mb-[48px]">
        <div className="bg-gray-30 sm:py-[24px] p-[24px] sm:px-0 rounded-[20px]">
          <OffersBlock data={proteinsCardsData} />
        </div>
      </div> */}
      {/* მედიკამენტები დაშლილი */}

      <div className="mb-[44px] mt-[48px] sm:mt-[32px] flex flex-col gap-5 ">
        {Object.keys(medicamentsWithCat).map((categoryKey) => (
          <div key={categoryKey} className="h-full w-full">
            {/* Display the category */}
            <h2 className="text-[24px] mb-8 font-bold text-blackPrimary-100 sm:hidden">
              {categoryKey === "face"
                ? "სახის და სხეულის მოვლის ბრენდები"
                : categoryKey === "mouth"
                ? "პირის ღრუს მოვლის ბრენდები"
                : categoryKey === "hair"
                ? "თმის მოვლის ბრენდები"
                : categoryKey}
            </h2>

            <h2 className="hidden sm:flex ml-4 text-[14px] mb-4 font-bold text-blackPrimary-100">
              {categoryKey === "face"
                ? "სახის მოვლა"
                : categoryKey === "mouth"
                ? "პირის ღრუს მოვლა"
                : categoryKey === "hair"
                ? "თმის მოვლა"
                : categoryKey}
            </h2>

            <div className="p-8 sm:p-4 md:p-6 bg-[#E8ECF44D] rounded-[20px] sm:rounded-none">
              <div className="flex">
                <div className="flex items-start gap-4">
                  <img
                    src="/images/icons/vitaicon.svg"
                    alt="icon"
                    width={24}
                    height={24}
                    className="mt-1 object-cover md:hidden"
                  />
                  <p className="text-base font-medium leading-6 text-[#070A1499] text-[16px]">
                    {categoryKey === "face"
                      ? "მუდმივმოქმედი, 35% ფასდაკლება მოცემული ოცი ბრენდის სრულ ასორტიმენტზე. ფასდაკლებით სარგებლობა შესაძლებელია ყოველდღე და ულიმიტოდ."
                      : categoryKey === "mouth"
                      ? "მუდმივმოქმედი, 35% ფასდაკლება მოცემული ოთხი ბრენდის სრულ ასორტიმენტზე. ფასდაკლებით სარგებლობა შესაძლებელია ყოველდღე და ულიმიტოდ."
                      : categoryKey === "hair"
                      ? "მუდმივმოქმედი, 35% ფასდაკლება მოცემული სამი ბრენდის სრულ ასორტიმენტზე. ფასდაკლებით სარგებლობა შესაძლებელია ყოველდღე და ულიმიტოდ."
                      : categoryKey}
                    {/* მუდმივმოქმედი, 35% ფასდაკლება სახისა და სხეულის მოვლის 20
                    ბრენდის სრულ ასორტიმენტზე. ფასდაკლებით სარგებლობა
                    შესაძლებელია ყოველ დღე და ულიმიტოდ. */}
                  </p>
                </div>{" "}
              </div>
              <div className="grid p-8 md:p-0 grid-cols-4 customxlsize:grid-cols-2 md:grid-cols-1 mt-[30px] md:mt-4 md:gap-3 gap-8">
                {medicamentsWithCat[categoryKey]
                  .slice(
                    0,
                    categoryKey === "face" && !showMore
                      ? 4
                      : medicamentsWithCat[categoryKey].length
                  )
                  .map((item: any, index: any) => (
                    <div
                      className="bg-[#fff] gap-3 flex items-center justify-between p-[10px] px-[12px] sm:py-[16px] sm:px-[24px] rounded-[12px]"
                      key={index}
                    >
                      <img
                        width={item.imageWidth}
                        height={item.imageHeight}
                        className="min-w-[100px] min-h-[100px] sm:max-w-[60px] sm:min-w-[60px] sm:min-h-[60px] sm:max-h-[60px] object-contain"
                        src={item.image}
                        alt={item.title}
                      />
                      <div className="mr-auto">
                        {" "}
                        <h3 className="font-bold text-[14px] text-[#070A14]  sm:text-blackPrimary-100">
                          {item.headerText}
                        </h3>
                        <h2 className="font-bold text-[12px] text-[#070A14] sm:text-blackPrimary-100">
                          {item.title}
                        </h2>
                      </div>
                      <button className="bg-raspberry-100 text-rg p-[8px] rounded-[8px] text-[#fff] font-bold whitespace-nowrap">
                        {item.discount}%
                      </button>
                    </div>
                  ))}
              </div>
              {categoryKey === "face" && (
                <div className="mt-4 w-full flex justify-end md:justify-center ">
                  <button
                    className="outline-none border-none flex items-center gap-2 text-blackPrimary-60 underline font-semibold text-[20px] mr-8 md:text-[16px] md:text-raspberry-100"
                    onClick={handleShowMoreToggle}
                  >
                    {width < 832 ? (
                      <>{showMore ? "ნაკლები" : "ყველა"}</>
                    ) : (
                      <>
                        <div className=" flex md:hidden">
                          <ReactSVG
                            src="/images/icons/eyedark.svg"
                            wrapper="span"
                          />
                        </div>

                        {showMore ? "იხილე ნაკლები" : "იხილე ყველა"}
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="h-[281px] mt-[48px] sm:h-[316px] mb-[32px] sm:mx-[16px] relative rounded-[16px] overflow-hidden">
        {width > 640 ? (
          <img
            className="object-cover object-left w-full h-full"
            src="/images/pharmacy/vitapspbanner.png"
          />
        ) : (
          <img
            className="object-cover object-left w-full h-full"
            src="/images/pharmacy/pspbannermobile.png"
          />
        )}
        <div className="absolute sm:w-[90%] left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] flex flex-col gap-8 items-center">
          <span className="font-demi md:text-[14px] text-[24px] text-[#fff] text-center">
            ხელმისაწვდომი სერვისებით და პროდუქტებით სარგებლობისთვის გახდი VITA
            ბარათის მფლობელი
          </span>
          {error?.isAuthorized ? (
            <Link to="/checkout">
              <button className="py-[20px] text-nowrap px-[40px] text-blackPrimary-100 font-demi text-rg rounded-[12px] bg-[#fff]">
                ბარათის ყიდვა
              </button>
            </Link>
          ) : (
            <a href="/secured">
              <button className="py-[20px] text-nowrap px-[40px] text-blackPrimary-100 font-demi text-rg rounded-[12px] bg-[#fff]">
                ბარათის ყიდვა
              </button>
            </a>
          )}
        </div>
      </div>
      {width <= 640 && (
        <div
          onClick={() => setIsContactOpen(true)}
          className="fixed z-20 shadowofcontactbtn bottom-4 bg-[#fff] flex items-center justify-center right-4 w-[60px] h-[60px] rounded-[50%]"
        >
          <ReactSVG src="/images/icons/phoneicon.svg" />
        </div>
      )}
    </div>
  );
};
