import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_ge from "./locales/ge/common.json";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {Provider} from "react-redux";
import {store} from "Store/store";
import {PersistProvider} from "Store/persistStore";
import {NotFound} from "Pages/404/404";
import App from "./App";
import User from "Pages/User";
import {Notifications} from "Pages/User/Notifications";
import {Card} from "Pages/User/Card";
import {BankCards} from "Pages/User/BankCards/BankCards";
import {OrderPage} from "Pages/User/Order/OrderPage";

import {MedSector} from "Pages/MedSector";
import {Corporate} from "Pages/Corporate";
import {Clinics} from "Pages/Clinics";
import {MobileMenu} from "Pages/User/MobileMenu/MobileMenu";
import {ClinicDetails} from "Pages/Clinics/clinic_details";
import {Checkout} from "Pages/Checkout";
import {Offers} from "Pages/Offers";
import {Check} from "Pages/Check";
import {CreateMemberPage} from "Pages/CreateMember";
import {AboutUs} from "Pages/About";
import {Psp} from "Pages/Pharmacy/Psp";
import {Bestpharma} from "Pages/Pharmacy/Bestpharma";
import Secured from "Pages/Secured";
import {FAQ} from "Pages/FAQ";
import {Layout} from "./Layouts/Main/Layout";
import "react-toastify/dist/ReactToastify.css";
import "./tostify.css";
import "./index.css";
import {SearchResults} from "Pages/SearchResults";
import {SuccessPage} from "./Pages/User/Order/Success";
import {FailurePage} from "./Pages/User/Order/Failure";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

i18next.init({
  interpolation: {escapeValue: false},
  lng: "ge",
  fallbackLng: "ge",
  resources: {
    ge: {
      common: common_ge,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children: [
      {
        path: "/",
        element: <App/>,
      },
      {
        path: "*",
        element: <NotFound/>,
      },
      //start - user pages
      {
        path: "user",
        element: <User/>,
      },
      {
        path: "user/card",
        element: <Card/>,
      },
      {
        path: "user/order",
        element: <OrderPage/>,
      },
      {
        path: "payment/success",
        element: <SuccessPage/>,
      },
      {
        path: "payment/failure",
        element: <FailurePage/>,
      },
      {
        path: "user/bankcards",
        element: <BankCards/>,
      },
      {
        path: "user/notifications",
        element: <Notifications/>,
      },
      {
        path: "user/menu",
        element: <MobileMenu/>,
      },
      //end - user pages
      {
        path: "/medsector",
        element: <MedSector/>,
      },
      {
        path: "/corporate",
        element: <Corporate/>,
      },
      {
        path: "/clinics",
        element: <Clinics/>,
      },
      {
        path: "/clinics/*",
        element: <Clinics/>,
      },
      {
        path: "/searchresults",
        element: <SearchResults/>,
      },
      {
        path: "/secured/*",
        element: <Secured/>,
      },
      {
        path: "detailed/*",
        element: <ClinicDetails/>,
      },
      {
        path: "checkout",
        element: <Checkout/>,
      },
      {
        path: "createmember",
        element: <CreateMemberPage/>,
      },
      {
        path: "offers/*",
        element: <Offers/>,
      },
      {
        path: "check",
        element: <Check/>,
      },
      {
        path: "about",
        element: <AboutUs/>,
      },
      // Pharmacy
      {
        path: "detailed/185",
        element: <Psp/>,
      },
      // {
      //   path: "detailed/186",
      //   element: <Bestpharma/>,
      // },
      {
        path: "faq",
        element: <FAQ/>,
      },
    ],
  },
]);

const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistProvider>
          <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18next}>
              <RouterProvider router={router}/>
            </I18nextProvider>
          </QueryClientProvider>
        </PersistProvider>
      </Provider>
    </React.StrictMode>
);

reportWebVitals();
